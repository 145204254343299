$placeItemsOptions: start, end, center, baseline, stretch;

.grid {
  display: grid;
}

@for $i from 1 through 13 {
  @if $i < 12 {
    .grid-cols-#{$i} {
      grid-template-columns: repeat($i, minmax(0, 1fr));
    }

    .grid-col-span-#{$i} {
      grid-column: span #{$i} / span #{$i};
    }
  }

  .grid-col-start-#{$i} {
    grid-column-start: $i;
  }

  .grid-col-end-#{$i} {
    grid-column-end: $i;
  }

  @if $i < 6 {
    .grid-row-#{$i} {
      grid-template-rows: repeat($i, minmax(0, 1fr));
    }

    .grid-row-span-#{$i} {
      grid-column: span #{$i} / span #{$i};
    }
  }

  @if $i < 7 {
    .grid-row-start-#{$i} {
      grid-column-start: $i;
    }

    .grid-row-end-#{$i} {
      grid-column-end: $i;
    }
  }
}

@each $option in $placeItemsOptions {
  .place-items-#{$option} {
    place-items: $option;
  }
}
