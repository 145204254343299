body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: rgba(var(--text-default));
  background-color: var(--surface-base);
  font-family: var(--base-font-family-name);
  overflow: scroll;
}
::-webkit-scrollbar {
  width: var(--size-1);
  height: var(--size-1);
}
::-webkit-scrollbar-track {
  background: var(--surface-200);
}
::-webkit-scrollbar-thumb {
  background: rgba(var(--border-200));
  border-radius: var(--radius-rounded);
}
::-webkit-scrollbar-thumb:hover {
  background: rgba(var(--border-300));
}
