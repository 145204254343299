.ee-side-nav {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  max-height: 100vh;
  z-index: 101;
  transition: width 0.2s ease;
  padding: var(--space-2);
  color: var(--text-100);
  width: var(--sidenav-width-collapsed);
  background-color: var(--surface-100);
  box-shadow: var(--shadow-sm);

  // Sidenav logo
  .ee-side-nav-logo {
    padding: var(--size-0p5);

    &.ee-logo-large {
      svg {
        width: unset;
      }
    }

    svg {
      height: var(--size-5);
      width: var(--size-5);
    }
  }

  // nav item
  .ee-side-nav-item {
    display: flex;
    position: relative;
    cursor: pointer;
    overflow: hidden;
    border-radius: var(--radius-default);
    list-style: none;
  }

  // nav link
  .ee-side-nav-link {
    flex: 1;
    display: inline-flex;
    align-items: center;
    text-decoration: none;
    color: currentColor;
    height: var(--size-6);
    padding: var(--space-1);
    transition: width 0.2s ease, background-color 0.2s ease;

    .ee-side-nav-icon {
      display: block;
      padding: var(--space-0p5);
    }

    .ee-side-nav-label {
      display: block;
      transition: padding 0.2s ease, opacity 0.15s ease;
      line-height: 1;
    }

    &.active {
      background-color: var(--button-bg-light-primary);
      color: var(--button-fg-light-primary);
    }

    &:hover {
      background-color: var(--button-bg-light-primary-hover);
    }
  }

  &.opened,
  &.pinned {
    width: var(--sidenav-width-expanded);

    // sub sidenav children container
    .notification-count {
      right: 0.75rem;
      top: 50%;
      transform: translateY(-50%);
    }

    .ee-side-nav-link {
      .ee-side-nav-label {
        opacity: 1;
        width: 100%;
        padding-left: var(--space-1);
      }
    }
  }
}
