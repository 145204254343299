/* Flymenu */
.ee-flymenu {
  position: relative;
  display: inline-block;
  user-select: none;

  &-btn {
    cursor: pointer;
  }

  &-content {
    position: absolute;
    top: calc(100% + 5px);
    right: 0;
    padding: var(--space-1);
    min-width: 12rem;
    position: absolute;
    right: 0;
    width: 100%;
    border-width: var(--size-px);
    border-style: solid;
    border-radius: var(--radius-md);
    border-color: rgba(var(--border-200));
    background-color: var(--surface-100);
    z-index: 100;

    .item {
      cursor: pointer;
      padding: var(--space-0p5) var(--space-1);

      &:hover {
        background-color: var(--button-bg-light-primary-hover);
      }
    }
  }
}
