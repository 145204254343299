@use "./../abstaract/sass-vars" as vars;
.page-content {
  grid-template-columns: 2fr 1fr;
  padding-bottom: var(--space-4);
}
//Match card
.match-card {
  position: relative;
  .align-center {
    align-items: center;
  }
  .status-badge {
    position: absolute;
    top: 50%;
    left: calc(var(--space-md) * -1);
    transform: translateY(-50%);
  }
  .match-card-grid {
    grid-template-columns: 1fr 13rem !important;
  }
  .live-left-section {
    grid-template-columns: 1fr auto 1fr;
  }
  .left-section {
    grid-template-columns: 1fr var(--space-11) 1fr;
  }
}

//Associated league card component
.associated-league-card {
  flex: 1;
  max-width: var(--size-10);
  min-width: var(--size-10);
  text-align: center;
}

//Table standing

.standing-table {
  overflow: hidden;
  table {
    padding: 1rem;
    width: 100%;
    border-collapse: collapse;
    th {
      height: var(--size-6);
      border-bottom: 1px solid rgb(var(--border-default));
    }
    td,
    th {
      text-align: center;
      padding: var(--space-2xs);
      &:first-child {
        padding-left: var(--space-xs);
      }
      &:last-child {
        padding-right: var(--space-xs);
      }
    }
  }
  .table-row {
    &.active-row {
      @each $color in vars.$colorOptions {
        &.color-#{$color} {
          background-color: rgb(var(--bg-translucent-#{$color}));
          position: relative;
          &:after {
            content: "";
            width: var(--size-0p5);
            height: 100%;
            background-color: rgb(var(--bg-solid-#{$color}));
            position: absolute;
            left: 0;
            top: 0;
          }
        }
      }
    }
    .data-cell {
      width: 8%;
    }
    .row-left-section {
      text-align: left;
      .index {
        width: var(--size-md);
      }
    }
  }
}
