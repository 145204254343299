//Player profile
.player-info {
  .player-box {
    position: relative;
    .player-grid-box {
      align-items: center;
      position: absolute;
      top: 0;
      right: 26%;
      left: 5%;
      bottom: 0;
      h6 {
        height: var(--size-md);
        line-height: var(--size-md);
        width: var(--size-xl);
        background-color: rgb(var(--bg-solid-primary));
        color: rgb(var(--white));
        border-radius: var(--radius-default);
      }
    }
  }
}
//Player attribute
.player-attribute {
  .attribute-chart {
    height: 23rem !important;
  }
  .attribute-compare {
    position: relative;
    .ee-input {
      width: 100%;
      padding-right: var(--space-lg);
    }
    .swap-icon {
      position: absolute;
      right: var(--space-2xs);
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
//player training
.training-stadium{
  min-width: var(--size-16);
  max-width: var(--size-16);
}
