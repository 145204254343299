.ee-doughnut-chart {
  position: relative;
  height: var(--size-16);
  width: var(--size-16);
}

.ee-doughnut-chart-fill {
  animation: doughnut-chart-fill 1s forwards;
  transform: rotate(-90deg);
  transform-origin: center;
}

.ee-doughnut-chart-info {
  animation: doughnut-chart-appear 1s forwards;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: inline-flex;
  flex-direction: column;
  text-align: center;
}

@keyframes doughnut-chart-fill {
  from {
    stroke-dasharray: 0 100;
  }
}

@keyframes doughnut-chart-appear {
  to {
    opacity: 1;
  }
}
