@use "./../abstaract/sass-vars" as vars;

.ee-toolbar {
  margin: 0 auto;
  padding: var(--space-1) 0;
  position: sticky;
  top: 0;
  width: 100%;
  max-width: var(--breakpoint-2xl);
  background-color: var(--surface-200);
  z-index: 100;
  > .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
