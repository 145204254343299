.login{
  .login-container{
    width: 65%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .login-logo-container{
    position: relative;
  }
  .login-logo{
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .login-input-password{
    width: 100%;
    padding-right: 10%;
  }
  .login-svg{
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 90%;
  }
}