@use "./../abstaract/sass-vars" as vars;

[data-theme="dark"] {
  /* background colors */
  --bg-solid-primary: var(--primary-600);
  --bg-solid-primary-dark: var(--primary-600);
  --bg-solid-accent: var(--accent-500);
  --bg-solid-accent-dark: var(--accent-600);
  --bg-solid-dark: var(--gray-400);
  --bg-solid-info: var(--info-700);
  --bg-solid-success: var(--success-700);
  --bg-solid-warning: var(--warning-700);
  --bg-solid-danger: var(--danger-700);

  --bg-translucent-primary: var(--primary-500) / 12%;
  --bg-translucent-accent: var(--accent-600) / 12%;
  --bg-translucent-info: var(--info-600) / 12%;
  --bg-translucent-success: var(--success-600) / 12%;
  --bg-translucent-warning: var(--warning-600) / 12%;
  --bg-translucent-danger: var(--danger-600) / 12%;
  --bg-translucent-dark: var(--gary-600) / 12%;

  /* border colors */
  --border-100: var(--gray-500);
  --border-200: var(--gray-400);
  --border-300: var(--gray-300);
  --border-default: var(--border-100);
  --border-dark: var(--border-200);
  --border-primary: var(--primary-600);
  --border-accent: var(--accent-300);
  --border-info: var(--info-300);
  --border-success: var(--success-300);
  --border-warning: var(--warning-300);
  --border-danger: var(--danger-300);

  /* foreground colors */
  --fg-solid-primary: var(--white);
  --fg-solid-accent: var(--white);
  --fg-solid-info: var(--white);
  --fg-solid-success: var(--white);
  --fg-solid-warning: var(--white);
  --fg-solid-danger: var(--white);
  --fg-solid-dark: var(--white);

  /* surface colors */
  --surface-100: rgba(26 24 23);
  --surface-200: rgba(15 13 12);
  --surface-300: rgba(38 36 35);
  --surface-base: var(--surface-200);
  --surface-invert: var(--white);

  /* text colors */
  --text-100: var(--white);
  --text-200: var(--gray-50);
  --text-300: var(--gray-100);
  --text-default: var(--text-200);
  --text-primary: var(--primary-600);
  --text-accent: var(--accent-300);
  --text-info: var(--info-300);
  --text-success: var(--success-300);
  --text-warning: var(--warning-300);
  --text-danger: var(--danger-300);
  --text-invert: var(--gray-900);

  /* colors */
  @each $color in vars.$colorOptions {
    /* buttons */
    // filled
    --button-bg-filled-#{$color}: rgba(var(--#{$color}-600));
    --button-bg-filled-#{$color}-hover: rgba(var(--#{$color}-700));
    --button-bg-filled-#{$color}-active: rgba(var(--#{$color}-800));

    // outline
    --button-bg-outline-#{$color}-hover: rgba(var(--#{$color}-100) / 40%);
    --button-bg-outline-#{$color}-active: rgba(var(--#{$color}-100) / 72%);
    --button-fg-outline-#{$color}: rgba(var(--#{$color}-600));

    // light
    --button-bg-light-#{$color}: rgba(var(--#{$color}-600) / 12%);
    --button-bg-light-#{$color}-hover: rgba(var(--#{$color}-600) / 30%);
    --button-bg-light-#{$color}-active: rgba(var(--#{$color}-600) / 88%);
    --button-fg-light-#{$color}: rgba(var(--#{$color}-600));

    // subtle
    --button-bg-subtle-#{$color}-hover: rgba(var(--#{$color}-100) / 40%);
    --button-bg-subtle-#{$color}-active: rgba(var(--#{$color}-100) / 80%);
    --button-fg-subtle-#{$color}: rgba(var(--#{$color}-600));

    /* bg-fg-options */

    /* filled */
    --bg-filled-#{$color}: rgba(var(--#{$color}-600));

    /* outline */
    --bg-outline-#{$color}: var(--transparent);
    --fg-outline-#{$color}: rgba(var(--#{$color}-600));

    /* light */
    --bg-light-#{$color}: rgba(var(--#{$color}-100) / 32%);
    --fg-light-#{$color}: rgba(var(--#{$color}-600));

    /* subtle */
    --fg-subtle-#{$color}: rgba(var(--#{$color}-600));
  }
  --fg-filled: rgba(var(--white));
  --bg-default: rgba(var(--white));
  --fg-default: currentColor;

  /* date-picker*/
  --close-fg: rgba(var(--white));

  /* avatar */
  --avatar-active-ring-color: rgba(var(--primary-500));

  /* skeleton*/
  --skeleton-shimmer-color: rgba(255, 255, 255, 0.05);

  /* tournament timeline*/
  --timeline-bg-color: rgba(var(--info-600));
}
