.manage-dropdown {
  width: 15rem;
}
.manage-dropdown-option {
  margin-top: 0.5rem;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.loader-line {
  width: 100%;
  height: var(--size-0p5);
  position: relative;
  overflow: hidden;
  background-color: var(--surface-200);
  -webkit-border-radius: var(--radius-rounded);
  -moz-border-radius: var(--radius-rounded);
  border-radius: var(--radius-rounded);
}

.loader-line:before {
  content: "";
  position: absolute;
  left: -50%;
  height: 3px;
  width: 40%;
  background-color: rgba(var(--primary-400));
  -webkit-animation: lineAnim 1s linear infinite;
  -moz-animation: lineAnim 1s linear infinite;
  animation: lineAnim 1s linear infinite;
  -webkit-border-radius: var(--radius-rounded);
  -moz-border-radius: var(--radius-rounded);
  border-radius: var(--radius-rounded);
}

@keyframes lineAnim {
  0% {
    left: -40%;
  }
  50% {
    left: 20%;
    width: 80%;
  }
  100% {
    left: 100%;
    width: 100%;
  }
}

.label-select {
  input[type="checkbox"] {
    display: none;
  }
  input[type="checkbox"] + label .label-check {
    display: none;
  }
  input[type="checkbox"] + label .label-uncheck {
    display: block;
  }
  input[type="checkbox"] + label {
    display: flex;
    background-color: var(--surface-200);
    cursor: pointer;
    font-size: var(--label-md-font-size);
    gap: var(--size-0p5);
    height: var(--size-4);
    align-items: center;
    padding: 0 0.75rem 0 var(--space-1);
  }

  input[type="checkbox"]:checked + label .label-check {
    display: block;
  }
  input[type="checkbox"]:checked + label .label-uncheck {
    display: none;
  }

  input[type="checkbox"]:checked + label {
    background-color: var(--button-bg-light-primary);
    color: var(--button-fg-light-primary);
  }
}

.label-checkbox {
  .label-check {
    background-color: rgba(var(--border-success));
    color: rgba(var(--white));
    border-width: var(--size-px);
    border-style: solid;
    border-radius: var(--radius-rounded);
    border-color: rgba(var(--border-success));
    width: 20px;
    height: 20px;
  }

  .label-uncheck {
    background-color: var(--surface-200);
    border-width: var(--size-px);
    border-style: solid;
    border-radius: var(--radius-rounded);
    border-color: rgba(var(--border-200));
    width: 20px;
    height: 20px;
  }
}

.commentary-match-time {
  width: var(--size-18);
  color: currentColor;
  text-align: center;
  font-weight: var(--weight-semibold);
  font-size: 1rem;
}

.sticky-top {
  position: sticky;
  top: var(--space-8);
  background-color: var(--surface-200);
  z-index: 100;
  padding-bottom: var(--space-1);
  ::after {
    content: "";
    height: var(--space-1);
    margin-top: -var(--space-1);
  }
}
