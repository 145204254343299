$roundedDividerSizeOptions: 3xs, 2xs, xs, sm, md;

.divider {
  border-style: var(--divider-border-style);
  border-color: rgba(var(--divider-color));
}

.rounded-divider {
  content: "";
  border-radius: var(--radius-rounded);
  background-color: rgba(var(--border-100));
  @each $size in $roundedDividerSizeOptions {
    &.size-#{$size} {
      height: var(--rounded-divider-#{$size});
      width: var(--rounded-divider-#{$size});
    }
  }
}
