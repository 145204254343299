.ee-anchor {
  appearance: none;
  cursor: pointer;
  text-decoration: none;

  &:not(.ee-anchor-btn) {
    background-color: transparent;
    border: none;
    color: rgba(var(--text-primary));
    display: inline;
    margin: 0;
    padding: 0;
    &:hover {
      text-decoration: underline;
    }
  }
}
