:root {
  /* ============================================== */
  /* colors */
  /* ============================================== */
  --primary-50: 255 237 231;
  --primary-100: 254 200 179;
  --primary-200: 253 173 143;
  --primary-300: 252 135 92;
  --primary-400: 252 112 60;
  --primary-500: 251 76 11;
  --primary-600: 228 69 10;
  --primary-700: 178 54 8;
  --primary-800: 138 42 6;
  --primary-900: 105 32 5;

  --accent-50: 236 234 249;
  --accent-100: 196 191 235;
  --accent-200: 168 160 226;
  --accent-300: 128 117 212;
  --accent-400: 104 90 204;
  --accent-500: 66 49 191;
  --accent-600: 60 45 174;
  --accent-700: 47 35 136;
  --accent-800: 36 27 105;
  --accent-900: 28 21 80;

  --gray-50: 242 239 237;
  --gray-100: 211 208 206;
  --gray-200: 172 169 167;
  --gray-300: 109 107 105;
  --gray-400: 85 83 82;
  --gray-500: 54 52 51;
  --gray-600: 38 36 35;
  --gray-700: 31 29 28;
  --gray-800: 23 21 20;
  --gray-900: 15 13 12;

  --info-50: 231 240 246;
  --info-100: 181 207 226;
  --info-200: 146 184 212;
  --info-300: 96 152 192;
  --info-400: 65 132 180;
  --info-500: 17 101 161;
  --info-600: 15 92 147;
  --info-700: 12 72 114;
  --info-800: 9 56 89;
  --info-900: 7 42 68;

  --success-50: 230 247 239;
  --success-100: 178 230 206;
  --success-200: 140 217 183;
  --success-300: 88 200 150;
  --success-400: 55 189 129;
  --success-500: 5 173 98;
  --success-600: 5 157 89;
  --success-700: 4 123 70;
  --success-800: 3 95 54;
  --success-900: 2 73 41;

  --warning-50: 255 244 231;
  --warning-100: 254 222 179;
  --warning-200: 253 205 142;
  --warning-300: 252 183 91;
  --warning-400: 252 169 59;
  --warning-500: 251 147 10;
  --warning-600: 228 134 9;
  --warning-700: 178 104 7;
  --warning-800: 138 81 6;
  --warning-900: 105 62 4;

  --danger-50: 255 233 231;
  --danger-100: 254 188 180;
  --danger-200: 253 156 143;
  --danger-300: 252 110 92;
  --danger-400: 252 82 61;
  --danger-500: 251 39 12;
  --danger-600: 228 35 11;
  --danger-700: 178 28 9;
  --danger-800: 138 21 7;
  --danger-900: 105 16 5;

  --black: 0 0 0;
  --white: 255 255 255;
  --transparent: rgba(0 0 0 / 0);

  /* radius */
  --radius-none: 0rem;
  --radius-xs: 0.125rem;
  --radius-sm: 0.25rem;
  --radius-md: 0.5rem;
  --radius-lg: 0.75rem;
  --radius-xl: 1rem;
  --radius-2xl: 2rem;
  --radius-rounded: 16rem;
  --radius-default: var(--radius-sm);

  /* size */
  --size-px: 1px;
  --size-0p5: 0.25rem;
  --size-1: 0.5rem;
  --size-2: 1rem;
  --size-3: 1.5rem;
  --size-4: 2rem;
  --size-5: 2.5rem;
  --size-6: 3rem;
  --size-7: 3.5rem;
  --size-8: 4rem;
  --size-9: 4.5rem;
  --size-10: 5rem;
  --size-11: 5.5rem;
  --size-12: 6rem;
  --size-13: 6.5rem;
  --size-14: 7rem;
  --size-15: 7.5rem;
  --size-16: 8rem;
  --size-17: 8.5rem;
  --size-18: 9rem;
  --size-1\/2: 50%;
  --size-1\/3: 33.333333%;
  --size-2\/3: 66.666667%;
  --size-1\/4: 25%;
  --size-2\/4: 50%;
  --size-3\/4: 75%;
  --size-1\/5: 20%;
  --size-2\/5: 40%;
  --size-3\/5: 60%;
  --size-4\/5: 80%;
  --size-1\/6: 16.666667%;
  --size-2\/6: 33.333333%;
  --size-3\/6: 50%;
  --size-4\/6: 66.666667%;
  --size-5\/6: 83.333333%;
  --size-1\/12: 8.333333%;
  --size-2\/12: 16.666667%;
  --size-3\/12: 25%;
  --size-4\/12: 33.333333%;
  --size-5\/12: 41.666667%;
  --size-6\/12: 50%;
  --size-7\/12: 58.333333%;
  --size-8\/12: 66.666667%;
  --size-9\/12: 75%;
  --size-10\/12: 83.333333%;
  --size-11\/12: 91.666667%;
  --size-full: 100%;
  --size-w-screen: 100vw;
  --size-h-screen: 100vh;

  --size-xs: var(--size-1);
  --size-sm: var(--size-2);
  --size-md: var(--size-3);
  --size-lg: var(--size-4);
  --size-xl: var(--size-5);

  /* space */
  --space-0: 0rem;
  --space-0p25: 0.125rem;
  --space-0p5: 0.25rem;
  --space-1: 0.5rem;
  --space-2: 1rem;
  --space-3: 1.5rem;
  --space-4: 2rem;
  --space-5: 2.5rem;
  --space-6: 3rem;
  --space-7: 3.5rem;
  --space-8: 4rem;
  --space-9: 4.5rem;
  --space-10: 5rem;
  --space-11: 5.5rem;
  --space-12: 6rem;

  --space-none: var(--space-0);
  --space-4xs: var(--space-0p25);
  --space-3xs: var(--space-0p5);
  --space-2xs: var(--space-1);
  --space-xs: var(--space-2);
  --space-sm: var(--space-3);
  --space-md: var(--space-4);
  --space-lg: var(--space-5);
  --space-xl: var(--space-6);
  --space-2xl: var(--space-7);
  --space-3xl: var(--space-8);

  /* shadow */
  --shadow-none: none;
  --shadow-xs: 0 2px 4px 0 rgba(0, 0, 0, 0.14), 0 2px 4px -2px rgba(0, 0, 0, 0.12);
  --shadow-sm: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
  --shadow-md: 0 8px 17px 2px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  --shadow-lg: 0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12);
  --shadow-xl: 0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12);
  --shadow-2xl: 0 35px 55px 8px rgba(0, 0, 0, 0.14), 0 14px 88px 11px rgba(0, 0, 0, 0.12);

  // android elevation
  // 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12)
  // 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12)
  // 0 8px 17px 2px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12)
  // 0 12px 22px -4px rgba(0, 0, 0, 0.14), 0 4px 7px 3px rgba(0, 0, 0, 0.12)
  // 0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12)
  // 0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12)
  // 0 35px 55px 8px rgba(0, 0, 0, 0.14), 0 14px 88px 11px rgba(0, 0, 0, 0.12)
  // 0 45px 75px 10px rgba(0, 0, 0, 0.14), 0 19px 120px 15px rgba(0, 0, 0, 0.12)
  // 0 55px 85px 14px rgba(0, 0, 0, 0.14), 0 23px 144px 18px rgba(0, 0, 0, 0.12)

  /* ============================================== */
  /* typography */
  /* ============================================== */

  --base-font-family-name: "Mulish", sans-serif;

  /* font size */
  --text-xs: 0.75rem;
  --text-sm: 0.875rem;
  --text-md: 1rem;
  --text-lg: 1.25rem;
  --text-xl: 1.5rem;
  --text-2xl: 2rem;
  --text-3xl: 2.5rem;
  --text-4xl: 2.75rem;
  --text-5xl: 3rem;
  --text-6xl: 3.5rem;
  --text-7xl: 4rem;
  --text-8xl: 5rem;
  --text-base: 1rem;

  /* font weight */
  --weight-thin: 200;
  --weight-light: 300;
  --weight-normal: 400;
  --weight-medium: 500;
  --weight-semibold: 600;
  --weight-bold: 700;
  --weight-extrabold: 800;

  /* letter spacing */
  --letter-spacing-tighter: -0.05em;
  --letter-spacing-tight: -0.025em;
  --letter-spacing-normal: 0em;
  --letter-spacing-wide: 0.025em;
  --letter-spacing-wider: 0.05em;

  /* heading */
  --heading-font-family-name: var(--base-font-family-name);
  --heading-font-family-style: Bold;
  --heading-font-weight: var(--weight-bold);

  --heading-1-font-size: 2.5rem;
  --heading-1-line-height: 3.25rem;
  --heading-2-font-size: 2rem;
  --heading-2-line-height: 2.5rem;
  --heading-3-font-size: 1.75rem;
  --heading-3-line-height: 2.25rem;
  --heading-4-font-size: 1.5rem;
  --heading-4-line-height: 2rem;
  --heading-5-font-size: 1.25rem;
  --heading-5-line-height: 1.75rem;
  --heading-6-font-size: 1rem;
  --heading-6-line-height: 1.5rem;

  /* body */
  --body-font-family-name: var(--base-font-family-name);
  --body-font-family-style: Regular;
  --body-font-weight: var(--weight-normal);

  --body-lg-font-size: 1rem;
  --body-lg-line-height: 1.5rem;
  --body-lg-letter-spacing: var(--letter-spacing-normal);
  --body-md-font-size: 0.875rem;
  --body-md-line-height: 1.25rem;
  --body-md-letter-spacing: var(--letter-spacing-normal);
  --body-sm-font-size: 0.75rem;
  --body-sm-line-height: 1rem;
  --body-sm-letter-spacing: var(--letter-spacing-normal);

  /* label */
  --label-font-family-name: var(--base-font-family-name);
  --label-font-family-style: Regular;
  --label-font-weight: var(--weight-semibold);

  --label-lg-font-size: 1rem;
  --label-lg-line-height: 1.25rem;
  --label-lg-letter-spacing: var(--letter-spacing-normal);
  --label-md-font-size: 0.875rem;
  --label-md-line-height: 1rem;
  --label-md-letter-spacing: var(--letter-spacing-normal);
  --label-sm-font-size: 0.75rem;
  --label-sm-line-height: 1rem;
  --label-sm-letter-spacing: var(--letter-spacing-normal);

  /* title */
  --title-font-family-name: var(--base-font-family-name);
  --title-font-family-style: Regular;
  --title-font-weight: var(--weight-bold);

  --title-lg-font-size: 1.25rem;
  --title-lg-line-height: 1.75rem;
  --title-lg-letter-spacing: var(--letter-spacing-normal);
  --title-md-font-size: 1rem;
  --title-md-line-height: 1.5rem;
  --title-md-letter-spacing: var(--letter-spacing-normal);
  --title-sm-font-size: 0.875rem;
  --title-sm-line-height: 1.25rem;
  --title-sm-letter-spacing: var(--letter-spacing-normal);

  /* ============================================== */
  /* components */
  /* ============================================== */

  /* avatar */
  --avatar-xs: var(--size-4);
  --avatar-sm: var(--size-5);
  --avatar-md: var(--size-6);
  --avatar-lg: var(--size-8);
  --avatar-xl: var(--size-10);
  --avatar-2xl: var(--size-12);
  --avatar-3xl: var(--size-15);
  --avatar-4xl: var(--size-16);
  --avatar-5xl: var(--size-17);
  --avatar-6xl: var(--size-18);

  /* button */
  --button-xs-size: var(--size-3);
  --button-sm-size: var(--size-4);
  --button-md-size: var(--size-5);
  --button-lg-size: var(--size-6);
  --button-xl-size: var(--size-7);

  --button-xs-text: var(--text-xs);
  --button-sm-text: var(--text-sm);
  --button-md-text: var(--text-sm);
  --button-lg-text: var(--text-md);
  --button-xl-text: var(--text-lg);

  --button-xs-padding: 0 var(--space-1);
  --button-sm-padding: 0 var(--space-1);
  --button-md-padding: 0 var(--space-2);
  --button-lg-padding: 0 var(--space-2);
  --button-xl-padding: 0 var(--space-3);

  --button-xs-icon-size: var(--text-md);
  --button-sm-icon-size: var(--text-lg);
  --button-md-icon-size: var(--text-lg);
  --button-lg-icon-size: var(--text-xl);
  --button-xl-icon-size: var(--text-2xl);

  --button-xs-gap: var(--space-0p5);
  --button-sm-gap: var(--space-1);
  --button-md-gap: var(--space-1);
  --button-lg-gap: var(--space-1);
  --button-xl-gap: var(--space-2);

  --disabled-bg: rgba(var(--border-100));
  --disabled-fg: rgba(var(--border-200));

  /* divider */
  --divider-xs: var(--size-px);
  --divider-sm: var(--size-1);
  --divider-md: var(--size-2);
  --divider-lg: var(--size-3);
  --divider-xl: var(--size-4);
  --divider-border-style: solid;
  --divider-color: var(--border-100);

  --rounded-divider-3xs: var(--size-px);
  --rounded-divider-2xs: var(--size-0p5);
  --rounded-divider-xs: var(--size-1);
  --rounded-divider-sm: var(--size-2);
  --rounded-divider-md: var(--size-3);

  /* pill */
  --small-pill-height: var(--size-3);
  --pill-height: var(--size-4);
  --big-pill-height: var(--size-5);
  --pill-padding: var(--space-2);

  /* sidenav */
  --sidenav-bg: var(--surface-200);
  --sidenav-link-active-bg: rgba(var(--bg-translucent-primary));
  --sidenav-link-active-fg: rgba(var(--text-primary));
  --sidenav-width-collapsed: var(--space-10);
  --sidenav-width-expanded: 16rem;

  /* modal */
  --modal-xs: 15rem;
  --modal-sm: 20rem;
  --modal-md: 35rem;
  --modal-lg: 55rem;
  --modal-xl: 90rem;
}
