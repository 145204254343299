.popover-color-picker {
  position: relative;
  display: inline-block;

  .color-picker-popover {
    position: absolute;
    bottom: 0;
    left: var(--space-6);
    z-index: 1000;

    .react-colorful__saturation {
      border-radius: var(--radius-default);
    }

    .react-colorful__hue {
      height: var(--size-1);
      border-radius: var(--radius-default);
      margin-top: var(--space-3);
    }
    .react-colorful__hue-pointer,
    .react-colorful__saturation-pointer {
      width: var(--size-3);
      height: var(--size-3);
      border-width: var(--size-px);
      border-style: solid;
      border-radius: var(--radius-rounded);
      border-color: rgba(var(--border-200));
      background-color: var(--surface-100);
    }
    .react-colorful__pointer-fill {
      width: 12px;
      height: 12px;
      margin: 5px;
      align-self: center;
      border-radius: var(--radius-rounded);
    }
  }

  .color-preview-container {
    position: relative;
  }

  .color-preview {
    position: absolute;
    right: var(--size-1);
    top: var(--size-1);
    height: var(--size-3);
    width: var(--size-3);
    border-radius: var(--radius-default);
    z-index: 1001;
  }
}

.input-color {
  width: var(--size-5);
  height: var(--size-5);
  background-color: transparent;
  border-width: var(--size-px);
  border-style: solid;
  border-radius: var(--radius-default);
  border-color: rgba(var(--border-200));
  background-color: var(--surface-100);
  cursor: pointer;
}
