.timeline-node {
  border: var(--size-px) solid rgba(var(--border-dark));
  border-radius: var(--radius-2xl);
  width: var(--size-5);
  min-width: var(--size-5);
  height: var(--size-8);

  &:after {
    display: block;
    content: "";
    background-color: rgba(var(--border-dark));
    position: absolute;
    bottom: 0;
    left: 50%;
    height: calc(100% - var(--size-8) - var(--space-2xs));
    width: var(--size-px);
  }
}

.timeline-node-container {
  position: relative;
}
