$spaceOptions: ("3xs", "2xs", "xs", "sm", "md", "lg", "xl", "2xl", "3xl");

@each $option in $spaceOptions {
  /* padding */
  .p-#{$option} {
    padding: var(--space-#{$option});
  }
  /* padding x-axis */
  .px-#{$option} {
    padding-right: var(--space-#{$option});
    padding-left: var(--space-#{$option});
  }
  /* padding y-axis */
  .py-#{$option} {
    padding-top: var(--space-#{$option});
    padding-bottom: var(--space-#{$option});
  }
  /* padding top */
  .pt-#{$option} {
    padding-top: var(--space-#{$option});
  }
  /* padding right */
  .pr-#{$option} {
    padding-right: var(--space-#{$option});
  }
  .pb-#{$option} {
    padding-bottom: var(--space-#{$option});
  }
  /* padding left */
  .pl-#{$option} {
    padding-left: var(--space-#{$option});
  }
  /* padding inline start */
  .ps-#{$option} {
    padding-inline-start: var(--space-#{$option});
  }
  /* padding inline end */
  .pe-#{$option} {
    padding-inline-end: var(--space-#{$option});
  }

  /* margin */
  .m-#{$option} {
    margin: var(--space-#{$option});
  }
  /* margin y-axis */
  .my-#{$option} {
    margin-top: var(--space-#{$option});
    margin-bottom: var(--space-#{$option});
  }
  /* margin x-axis */
  .mx-#{$option} {
    margin-right: var(--space-#{$option});
    margin-left: var(--space-#{$option});
  }

  .mx-auto {
    margin-right: auto;
    margin-left: auto;
  }

  /* margin top */
  .mt-#{$option} {
    margin-top: var(--space-#{$option});
  }
  /* margin right */
  .mr-#{$option} {
    margin-right: var(--space-#{$option});
  }
  /* margin bottom */
  .mb-#{$option} {
    margin-bottom: var(--space-#{$option});
  }
  /* margin left */
  .ml-#{$option} {
    margin-left: var(--space-#{$option});
  }

  /* gap */
  .gap-#{$option} {
    gap: var(--space-#{$option});
  }
  /* gap y-axis */
  .gap-y-#{$option} {
    row-gap: var(--space-#{$option});
  }
  /* gap x-axis */
  .gap-x-#{$option} {
    column-gap: var(--space-#{$option});
  }
}
