.news-card-min {
  min-height: var(--size-14);
}
.match-time-width {
  min-width: var(--space-6);
}

.stat-width {
  min-width: var(--space-6);
}

.tab-big {
  height: var(--size-5);
}

.stat-title-width {
  min-width: var(--size-14);
}

.match-timeline-container {
  position: relative;
  width: 100%;
  height: var(--size-12);

  .match-timeline {
    width: 100%;
    height: var(--size-0p5);
    background-color: var(--surface-100);
    border-radius: var(--border-radius-sm);
  }
  .match-timeline-event-top,
  .match-timeline-event-bottom {
    position: absolute;
    transform: translateX(-50%);
  }
  .match-timeline-event-top {
    bottom: 100%;
  }
  .match-timeline-event-bottom {
    top: 100%;
  }
}

.match-header {
  flex: 1 0 0;
}

.clickable {
  cursor: pointer;
}

.w-100 {
  width: 100%;
}

//match-form admin
.formation-container {
  position: relative;
  .lineup-grid {
    position: absolute;
    top: 1%;
    left: 0;
    right: 0;
    bottom: 12%;
    grid-template-columns: repeat(15, 1fr);
    grid-template-rows: repeat(11, 1fr);
    .lineup-grid-cell {
      position: relative;
      .lineup-grid-cell-content {
        width: var(--size-12);
        height: var(--size-12);
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
  .bench-grid {
    position: absolute;
    left: 0;
    right: 0;
    top: 85%;
    bottom: 5%;
    grid-template-columns: repeat(9, 1fr);
    grid-template-rows: repeat(1, 1fr);
    .bench-grid-cell {
      position: relative;
      .bench-grid-cell-content {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
  .player-cell-container {
    width: var(--size-12);
    height: var(--size-12);
    cursor: default;
    position: relative;
    .remove-button {
      position: absolute;
      left: 50%;
      bottom: 50%;
      z-index: 1;
      transform: translate(-50%, -50%);
    }
  }
}

.player-grid {
  .player-add-display {
    cursor: pointer;
    visibility: hidden;
  }
  &:hover .player-add-display {
    visibility: visible;
  }
}
