.tab {
  display: flex;
  align-items: center;
  background-color: (var(--surface-100));
  gap: var(--space-1);
  overflow: hidden;
  position: relative; 
  overflow-x: scroll;
  -ms-overflow-style: none;
  scrollbar-width: none;
  scroll-behavior: smooth;
  white-space: nowrap;

  &-default {
    .tab-item {
      background-color: var(--surface-100);
      border-color: rgba(var(--border-default));
    }
  }

  &-filled {
    background-color: var(--surface-100);
    padding: var(--space-1);
    border-radius: var(--radius-default);
  }
}

.tab-item {
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  box-shadow: none;
  cursor: pointer;
  font-family: inherit;
  color: currentColor;
  text-align: center;
  text-decoration: none;
  transition: background-color 0.15s ease, color 0.15s ease,
    box-shadow 0.15s ease, border-color 0.15s ease;
  border-style: solid;
  border-width: var(--size-px);
  background-color: var(--transparent);

  height: var(--size-4);
  border-radius: var(--radius-default);
  padding: 0 var(--space-2);
  font-size: var(--text-sm);
  font-weight: var(--weight-semibold);
  border-color: var(--transparent);
  gap: var(--space-2xs);

  &:hover {
    background-color: var(--button-bg-subtle-primary-hover);
  }

  &:focus {
    &:focus-visible {
      outline-offset: 0.125rem;
      outline: 0.125rem solid rgba(var(--text-default));
    }
  }

  &.active {
    background-color: var(--button-bg-filled-primary);
    color: var(--button-fg-filled);
    border-color: var(--button-bg-filled-primary);

    &:hover {
      background-color: var(--button-bg-filled-primary-hover);
    }
  }
}

.scroll-button-left,.scroll-button-right{
  justify-content: center;
  align-items: center;
  height: var(--size-4);
  cursor: pointer;
  display: flex;
  background-color: transparent;
}

.scroll-button-left {
  margin-right: var(--space-2);
}

.scroll-button-right{
  margin-left: var(--space-2);
}

.tab::-webkit-scrollbar {
  display: none;
}

.filter-tabs{
  padding: var(--space-0p5);
  gap: var(--space-0p5);
}

.filter-tab{
  padding: 0 var(--space-5);
}
