@use "./../abstaract/sass-vars" as vars;

.container {
  display: block;
  width: 100%;
  padding: 0 var(--space-xl);
  margin: 0 auto;
  max-width: vars.$breakpoint-2xl;

  // @each $breakpoint, $width in vars.$breakpoint-options {
  //   @media (min-width: $width) {
  //     max-width: $width;
  //   }
  // }
}
