.snackbar-container {
  position: fixed;
  right: var(--space-2);
  top: var(--space-2);
  z-index: 1500;
}

@mixin snackbar-base() {
  min-width: 30rem;
  max-width: 30rem;
  height: auto;
  border-width: var(--size-px);
  border-style: solid;
}

@mixin snackbar-success() {
  &.snackbar-success {
    border-color: rgba(var(--border-success));
  }
}

@mixin snackbar-warning() {
  &.snackbar-warning {
    border-color: rgba(var(--border-warning));
  }
}

@mixin snackbar-danger() {
  &.snackbar-danger {
    border-color: rgba(var(--border-danger));
  }
}

.snackbar {
  @include snackbar-base();
  @include snackbar-success();
  @include snackbar-warning();
  @include snackbar-danger();
}
