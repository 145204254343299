$colorOptions: primary, accent,;

.progressbar{
    height: var(--size-0p5);
    width: 100%;
    overflow: hidden;
    background-color: var(--surface-300);
    border-radius: var(--radius-xl);
    .progressbar-fill{
        min-width: 0.5%;
        height: 100%;
        border-radius: var(--radius-xl);
        @each $color in $colorOptions {
            &.color-#{$color} {
              background-color: rgb(var(--bg-solid-#{$color}));
            }
          }
    }
}